import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PrintIcon from '@mui/icons-material/Print';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { MAX_RTH_LABEL_COUNT, ASSEMBLY } from 'lib/constants';
import { CloseIcon } from 'components/icons';
import { StatefulButton } from 'components/shared';
import { printLabels } from 'redux/productionProgress/rth/actions';

import styles from './styles';
import { PrinterContext } from '../PrinterProvider';

const useStyles = makeStyles(styles);

const PrinterDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { printers } = useSelector((state) => state.printers);
  const { printLabelsFetching, printLabelsFailed } = useSelector(
    (state) => state.rthProductionProgress
  );

  const {
    printerDialogOpen,
    setPrinterDialogOpen,
    printSubmission = {},
  } = useContext(PrinterContext);

  const [labelCount, setLabelCount] = useState('');
  const [selectedPrinter, setSelectedPrinter] = useState();

  const progress = printSubmission && printSubmission.progress;
  const sublotId = printSubmission && printSubmission.sublotId;
  const isReprint = printSubmission && printSubmission.isReprint;
  const dialogHeader = isReprint
    ? 'Print Labels'
    : `${progress?.activity} Submission Successful`;
  const mealDisplay = `${progress?.mealLetter} - ${progress?.mealTitle}`;
  const labelCountRange = Array.from(Array(MAX_RTH_LABEL_COUNT).keys()).map(
    (num) => num + 1
  );
  const submissionType = progress?.activity === ASSEMBLY ? 'meals' : 'recipes';

  const resetAndClose = () => {
    setLabelCount('');
    setSelectedPrinter(null);
    setPrinterDialogOpen(false);
  };

  const onSubmit = () => {
    dispatch(
      printLabels({
        activity: progress.activity,
        sublotId: sublotId,
        progressId: progress.id,
        labelCount: labelCount,
        printerId: selectedPrinter,
      })
    );
  };

  return (
    <Dialog
      open={printerDialogOpen}
      onClose={resetAndClose}
      data-testid="printer-dialog"
    >
      <div className={classes.closeButton}>
        <IconButton onClick={resetAndClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>

      <DialogTitle className={classes.header}>
        {isReprint ? (
          <PrintIcon />
        ) : (
          <CheckCircleOutlineIcon className={classes.successIcon} />
        )}
        {dialogHeader}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.submissionInfo}>
          {progress?.activity != ASSEMBLY && (
            <div>{`Ingredient: ${progress?.ingredientName} - ${progress?.ingredientId}`}</div>
          )}
          <div>{`Meal: ${mealDisplay}`}</div>
          <div>{`Number of ${submissionType}: ${printSubmission?.submissionCount}`}</div>
        </div>
        <div className={classes.form}>
          <div className={classes.quantityQuestion}>
            <InputLabel className={classes.quantityInputLabel}>
              How many labels would you like to print?
            </InputLabel>
            <Select
              variant="outlined"
              fullWidth
              value={labelCount}
              onChange={(e) => setLabelCount(e.target.value)}
              data-testid="label-count-select"
            >
              {labelCountRange.map((count) => (
                <MenuItem key={count} value={count}>
                  {count}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.printerQuestion}>
            <InputLabel className={classes.printerInputLabel}>
              Choose a Printer
            </InputLabel>
            <ToggleButtonGroup
              exclusive
              className={classes.buttonGroup}
              size="medium"
              value={selectedPrinter}
              onChange={(_, newPrinter) => setSelectedPrinter(newPrinter)}
              data-testid="printer-select"
            >
              {printers.map((printer) => {
                return (
                  <ToggleButton
                    className={classes.printerButton}
                    key={printer.id}
                    value={printer.id}
                    data-testid={`printer-${printer.id}`}
                  >
                    {printer.name}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button className={classes.cancelButton} onClick={resetAndClose}>
          Cancel Print
        </Button>
        <StatefulButton
          loading={printLabelsFetching}
          failed={printLabelsFailed}
          buttonTextOptions={{
            DEFAULT: 'Print',
            LOADING: 'Printing...',
            ERROR: 'Print Failed',
            SUCCESS: 'Printed!',
          }}
          type="submit"
          onClick={onSubmit}
          classes={{ root: classes.printButton }}
          disabled={!labelCount || !selectedPrinter}
          successCallback={resetAndClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PrinterDialog;
