import { GREY_150, WHITE, BLACK, LIGHTER_BLUE, ROBOTO } from 'lib/constants';

export default {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    fontSize: 'small',
    marginBottom: '20px',
  },
  headerRow: {
    height: '34px',
    backgroundColor: GREY_150,
  },
  row: {
    height: '34px',
  },
  leftCell: {
    padding: '0px 0px 0px 10px',
    textAlign: 'left',
    borderLeft: '1px solid',
    borderBottom: 'none',
    backgroundColor: WHITE,
    minWidth: '50px',
    fontFamily: ROBOTO,
  },
  rightCell: {
    padding: '0px 10px 0px 0px',
    textAlign: 'right',
    borderBottom: 'none',
    borderRight: '1px solid',
    backgroundColor: WHITE,
    backgroundSize: '95%',
    minWidth: '50px',
    fontFamily: ROBOTO,
  },
  dayName: {
    backgroundColor: LIGHTER_BLUE,
    fontSize: '14px',
    color: BLACK,
    textAlign: 'left',
    padding: '0px 0px 0px 10px',
    borderLeft: '1px solid',
    borderBottom: 'none',
    minWidth: '50px',
    fontFamily: ROBOTO,
  },
  percentComplete: {
    backgroundColor: LIGHTER_BLUE,
    fontSize: '14px',
    textAlign: 'right',
    padding: '0px 10px 0px 0px',
    borderRight: '1px solid',
    borderBottom: 'none',
    minWidth: '50px',
    fontFamily: ROBOTO,
  },
  cellBigText: {
    fontSize: '15px',
  },
  completedDay: {
    opacity: '0.3',
  },
  bold: {
    fontWeight: '900',
  },
};
