export const FIVE_MINUTE_INTERVAL = 600000;
export const OVERVIEW = 'overview';
export const COMPOUND_INGREDIENTS = 'compound-ingredients';
export const MEALS = 'meals';
export const MEAL_ITEM_TYPE = 'meal';
export const INGREDIENT_ITEM_TYPE = 'ingredient';
export const SMALL_PROGRESS_BAR_PIXEL_COUNT = 275;
export const ASSEMBLY_BAR_PIXEL_COUNT = 250;
export const LARGE_PROGRESS_BAR_PIXEL_COUNT = 350;
export const COMPOUND_INGREDIENTS_STEP_ORDER = [
  'completed',
  'inProgress',
  'onHold',
  'notStarted',
];
export const ASSEMBLY_STEP_ORDER = ['assembled', 'readyForAssembly', 'toGoal'];

// Daily Splits Example Data

export const PROGRESS_TRACKER_DAY_SPLITS_EXAMPLE_MEAL = {
  id: 10001,
  title: 'Sample Meal',
  day_split_goals: [
    {
      day: 'SAT',
      num_left_to_complete: 0,
      total_to_complete_for_day: 503,
      percent_completed: 100,
    },
    {
      day: 'SUN',
      num_left_to_complete: 0,
      total_to_complete_for_day: 366,
      percent_completed: 100,
    },
    {
      day: 'MON',
      num_left_to_complete: 0,
      total_to_complete_for_day: 320,
      percent_completed: 100,
    },
    {
      day: 'TUE',
      num_left_to_complete: 0,
      total_to_complete_for_day: 167,
      percent_completed: 100,
    },
    {
      day: 'WED',
      num_left_to_complete: 24,
      total_to_complete_for_day: 56,
      percent_completed: 57,
    },
    {
      day: 'THU',
      num_left_to_complete: 15,
      total_to_complete_for_day: 15,
      percent_completed: 0,
    },
  ],
};
