// colors
export const HC_GREEN = '#008600';
export const DARK_HC_GREEN = '#377d32';
export const SUCCESS_GREEN = '#009f32';
export const LIGHT_RED = '#ffe6e6';
export const ERROR_RED = '#f74647';
export const DARK_RED = '#cc3737';
export const BLUE = '#006ED9';
export const GREY_BLUE = '#4C5D74';
export const DARK_BLUE = '#2A73E1';
export const LIGHT_PINK = '#FFB6C1';
export const CLICKABLE_BLUE = '#123cb2';
export const TRANQUIL_BLUE = '#0B79D0';
export const FRESH_EASY_BLUE = '#2A7DE1';
export const RETAIL_ORANGE = '#F07B00';
export const YELLOW = '#ffff00';
export const WARNING_YELLOW = '#FFF4E5';
export const AMBER_YELLOW = '#ED6C0299';
export const ORANGE_YELLOW = '#FFCB00';
export const DARK_LAVENDER = '#824BA9';
export const PEACH_ORANGE = '#FFB547';
export const SAPPHIRE_BLUE = '#303F9F';

export const LIGHT_GREEN = '#83c68e';
export const LIGHTER_GREEN = '#E6F3E6';
export const LIGHT_BLUE = '#f7f9fb';
export const LIGHTER_BLUE = '#e9f4fe';
export const WHITE = '#FFF';
export const GREY_050 = '#fafafa';
export const GREY_100 = '#F8F8F8';
export const GREY_150 = '#F3F6F9';
export const GREY_200 = '#d2d4d6';
export const GREY_400 = '#979797';
export const GREY_500 = '#8b8e90';
export const GREY_600 = '#7D7D7D';
export const GREY_650 = '#5e5e5e';
export const GREY_700 = '#4a4c4e';
export const GREY_750 = '#2b2b2b';
export const GREY_800 = '#464646';
export const RED_700 = '#DC2626';
export const BORDER_GREY = '#bdbdbd';
export const POD_GREY = GREY_200;
export const BLACK = '#000';

export const AUTUMN_GRADIENT =
  'linear-gradient(to bottom, #eacd93 10%, #d06c07 100%)';

export const POSITIVE_GREEN = SUCCESS_GREEN;
export const LIGHT_POSITIVE_GREEN = '#167f00';
export const DARK_POSITIVE_GREEN = '#deedd6';

export const ERROR_DARK = '#';
export const WARNING_DARK = '#C77700';

export const NEGATIVE_RED = ERROR_RED;
export const LIGHT_NEGATIVE_RED = '#fdd6dd';
export const DARK_NEGATIVE_RED = '#df0000';
export const QA_ERROR_RED = '#f84547';

export const PRODUCTION_SMOCK = '#1f2e3e';
export const QA_SMOCK = '#bbd5ef';

export const DIVIDER_GREY = 'rgba(0, 0, 0, 0.12)';
export const BACKDROP = 'rgba(0, 0, 0, 0.20)';

export const ON_TARGET_GREY = '#8c8c8c';
export const BELOW_TARGET_RED = '#e2544d';
export const AHEAD_OF_TARGET_GREEN = '#669550';

// fonts
export const ROBOTO = 'Roboto';

// breakpoints
export const SMALL_SCREEN = '480px';
export const MEDIUM_SCREEN = '850px';
export const ANYTHING_BIGGER_THAN_MEDIUM = '851px';
export const LARGE_SCREEN = '1200px';
export const EXTRA_LARGE_SCREEN = '1400px';
export const HUGE_SCREEN = '1600px';

export const PHONE_SCREEN = `(max-width: ${SMALL_SCREEN})`;
export const TABLET_SCREEN = `(max-width:${MEDIUM_SCREEN}) and (min-width:${SMALL_SCREEN})`;
export const TABLET_SCREEN_OR_SMALLER = `(max-width: ${MEDIUM_SCREEN})`;
export const LARGER_THAN_PHONE = `(min-width: ${SMALL_SCREEN})`;
export const LARGER_THAN_TABLET = `(min-width: ${ANYTHING_BIGGER_THAN_MEDIUM})`;
export const LARGE_SCREEN_OR_LARGER = `(min-width: ${LARGE_SCREEN})`;

// media-queries
export const MEDIA_QUERY_PHONE_SCREEN = `@media ${PHONE_SCREEN}`;
export const MEDIA_QUERY_TABLET_OR_SMALLER = `@media ${TABLET_SCREEN_OR_SMALLER}`;
export const MEDIA_QUERY_LARGER_THAN_PHONE = `@media ${LARGER_THAN_PHONE}`;
export const MEDIA_QUERY_TABLET = `@media ${TABLET_SCREEN}`;

export const BIGGER_THAN_TABLET = `@media (min-width: ${ANYTHING_BIGGER_THAN_MEDIUM})`;
export const BETWEEN_L_AND_XL = `@media (max-width:${EXTRA_LARGE_SCREEN}) and (min-width:${LARGE_SCREEN})`;
export const MEDIA_QUERY_LARGE_SCREEN = `@media ${LARGE_SCREEN_OR_LARGER}`;

export const MEDIA_QUERY_HUGE_SCREEN_OR_SMALLER = `@media (max-width: ${HUGE_SCREEN})`;

export const SNACK_BAR_DURATION = 3500;

// emojis
export const EMOJI_FACE_WITH_MONOCLE = '🧐';
