import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const DaySplits = ({ meal }) => {
  const classes = useStyles();
  const { day_split_goals: daySplits } = meal;

  const isCompleted = (goal) => goal.num_left_to_complete < 1;

  const renderHeaderCells = (goal) => (
    <React.Fragment key={goal.day}>
      <TableCell
        colSpan={1}
        className={classNames(
          classes.dayName,
          classes.bold,
          isCompleted(goal) && classes.completedDay
        )}
      >
        {goal.day}
      </TableCell>
      <TableCell
        colSpan={1}
        className={classNames(
          classes.percentComplete,
          classes.bold,
          isCompleted(goal) && classes.completedDay
        )}
      >
        {goal.percent_completed}%
      </TableCell>
    </React.Fragment>
  );

  const renderRowLabelCells = (goal) => (
    <React.Fragment key={goal.day}>
      <TableCell
        className={classNames(
          classes.leftCell,
          isCompleted(goal) && classes.completedDay
        )}
      >
        Left
      </TableCell>
      <TableCell
        className={classNames(
          classes.rightCell,
          isCompleted(goal) && classes.completedDay
        )}
      >
        Total
      </TableCell>
    </React.Fragment>
  );

  const renderBodyCells = (goal) => (
    <React.Fragment key={goal.day}>
      <TableCell
        className={classNames(
          classes.leftCell,
          isCompleted(goal) && classes.completedDay,
          classes.cellBigText,
          classes.bold
        )}
      >
        {goal.num_left_to_complete?.toLocaleString()}
      </TableCell>
      <TableCell
        className={classNames(
          classes.rightCell,
          isCompleted(goal) && classes.completedDay,
          classes.cellBigText,
          classes.bold
        )}
      >
        {goal.total_to_complete_for_day?.toLocaleString()}
      </TableCell>
    </React.Fragment>
  );

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          {daySplits.map(renderHeaderCells)}
        </TableRow>
        <TableRow className={classes.row}>
          {daySplits.map(renderRowLabelCells)}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.bodyRow}>
          {daySplits.map(renderBodyCells)}
        </TableRow>
      </TableBody>
    </Table>
  );
};

DaySplits.propTypes = {
  meal: PropTypes.object.isRequired,
};

export default DaySplits;
